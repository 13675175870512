import { Helmet } from 'react-helmet'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { getCMSPageDataByContentType } from 'src/cms/client'
import { slugify } from 'src/utils/slugfy'
import { DepartmentPageTemplate } from 'src/components/department/DepartmentPageTemplate'
import type { PropsCMS } from 'src/components/sports/utils/types'

function AppPage(props: PropsCMS) {
  const { serverData } = props

  if (serverData === null) {
    return null
  }

  const { cmsData } = serverData

  const seo = cmsData.seo.siteMetadataWithSlug

  return (
    <>
      <Helmet>
        <meta name="keywords" content={seo.keywords} />
        <meta property="og:image" content={seo.ogImage} />
      </Helmet>
      <GatsbySeo
        title={seo.title}
        description={seo.description}
        language="pt-br"
        canonical={`https://www.decathlon.com.br/aplicativo/${seo.slug}`}
        openGraph={{
          type: 'website',
          url: `https://www.decathlon.com.br/aplicativo/${seo.slug}`,
          title: seo.title,
          description: seo.description,
        }}
      />
      <DepartmentPageTemplate {...props} />
    </>
  )
}

export const getServerData = async ({
  params: { slug },
}: {
  query: Record<string, string>
  params: Record<string, string>
}) => {
  const newSlug = slugify(slug, '+')

  const appCampaignCms = await getCMSPageDataByContentType(
    'campanha-aplicativo',
    {
      filters: { name: newSlug },
    }
  )

  return {
    status: 200,
    props: {
      cmsData: appCampaignCms,
    },
    headers: {
      'cache-control': 'no-cache',
    },
  }
}

export default AppPage
