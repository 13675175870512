import { Pictos } from 'src/components/common/Pictos'
import { BannerFull } from 'src/components/department/BannerFull'
import { BannerMood } from 'src/components/department/BannerMood'
import { NavigateBySports } from 'src/components/department/NavigateBySports'
import { NavigateByCategories } from 'src/components/department/NavigateByCategories'
import type { ComponentType } from 'react'
import type { DepartmentSections } from 'src/components/department/types/sections'
import GridBanner from 'src/components/home/GridBanner'
import CollectionShelfv2 from 'src/components/restructure/product/shelves/CollectionShelfV2'

interface ServerDataSportPage {
  cmsData: {
    sections: DepartmentSections[]
  }
}

interface DepartmentPageTemplateProps {
  serverData: ServerDataSportPage
}

export function DepartmentPageTemplate(props: DepartmentPageTemplateProps) {
  const COMPONENTS: Record<string, ComponentType<any>> = {
    'Banners Secundários [Home]': GridBanner,
    ' Banner Full [Departamento]': BannerFull,
    ' Banners Mood [Departamento]': BannerMood,
    'Navegue por esporte [Departamento]': NavigateBySports,
    'Navegue por categoria [Departamento]': NavigateByCategories,
    ' Pictos [Departamento]': Pictos,
    ' Vitrine - Nossos queridinhos [Departamento]': CollectionShelfv2,
  }

  const { cmsData } = props.serverData

  if (!cmsData) {
    return null
  }

  const { sections } = cmsData

  return (
    <div>
      {sections?.map(({ name, data }) => {
        const Component = COMPONENTS[name]

        if (!Component) {
          console.info(
            `Could not find component for block ${name}. Add a new component for this block or remove it from the CMS`
          )

          return <></>
        }

        return <Component key={`cms-section-${name}`} {...data} />
      })}
    </div>
  )
}
